/**
 *
 * @date   20.06.2017 13:50
 * @author Michael Raith <michael.raith@bcmsolutions.de>
 */

/* global $, module, window */

/**
 * Condition module for:
 * - Parsing value for a given type e.g. "1" as "int".
 * - Check if a value matches a given condition.
 *
 * @type {{equalTypes, conditionTypes, parser, getParsedValue, getInputValue, prepareSelector, isEqual, isAllEqual}}
 */
var Condition = (function ($) {
    'use strict';

    /**
     * Parse a value and return a string.
     *
     * @param {string|*} str
     *
     * @returns {string}
     */
    var parseString = function parseString(str) {
        return String(str);
    };

    /**
     * Parse a value and return a boolean.
     *
     * @param {boolean|*} bool
     *
     * @returns {boolean}
     */
    var parseBool = function parseBool(bool) {
        if (bool === 'true' || bool === 1) {
            return true;
        }
        if (bool === 'false' || bool === 0) {
            return false;
        }

        return Boolean(bool);
    };

    var conditionTypes = {
        INT: /^int(eger)?$/i,
        FLOAT: /^float$/i,
        STR: /^str(ing)?$/i,
        BOOL: /^bool(ean)?$/i
    };

    var conditionTypeMapping = {
        int: parseInt,
        integer: parseInt,
        float: parseFloat,
        str: parseString,
        string: parseString,
        bool: parseBool,
        boolean: parseBool
    };

    /**
     * Parse a condition value for a given type.
     *
     * @param {object}                  conditionData
     * @param {string|boolean|number|*} val
     *
     * @returns {string|boolean|number|*}
     */
    var getParsedValue = function getParsedValue(conditionData, val) {
        var type = conditionData.compare.type;

        if (typeof conditionTypeMapping[type] === 'undefined') {
            throw new Error('Invalid condition type "' + type + '"!');
        }

        return conditionTypeMapping[type](val);
    };

    /**
     * @type {object}
     */
    var equalTypes = {
        NOT_EQ: 'not-equal',
        EQUAL: 'equal',
        GREATER: 'greater',
        GREATER_THAN: 'greater-than',
        GT: 'gt',
        LESS: 'less',
        LESS_THAN: 'less-than',
        LT: 'lt',
        AND: 'and',
        OR: 'or',
    };

    /**
     * Get the input value for a form element depending on its type and the condition's equal type.
     *
     * @param {HTMLElement|object|jQuery} element
     * @param {object}                    conditionData
     *
     * @returns {string|number|boolean|null|*}
     *
     * @example
     * Parse a radio button group with two elements as "boolean". If the first element is selected, it will equal
     * to "false". If the second element is selected, it will equal to "true".
     *
     * Parse a radio button group with n-elements as "integer". Return the number of the selected element beginning
     * with zero (first element).
     */
    var getInputValue = function getInputValue(element, conditionData) {
        var $el = $(element);
        if (!$el || $el.length === 0 || !($el[0] instanceof HTMLElement)) {
            return null;
        }

        var $parents = $el.parents('.form-group');
        var elementType = $el.prop('type');

        var originalValue = $el.val();
        var value = originalValue;

        var conditionType = conditionData.compare.type;
        var values = {};

        // Radio buttons
        if (elementType === 'radio' && (Condition.conditionTypes.BOOL.test(conditionType) || Condition.conditionTypes.INT.test(conditionType))) {
            // First get the proper selected radio button value.
            originalValue = $parents.find('input[type="radio"]:checked').val();
            value = originalValue;

            $parents.find('input[type="radio"]').each(function (key, el) {
                values[$(el).val()] = key;
            });
        }
        // Checkbox
        else if (elementType === 'checkbox') {
            var isChecked = $el.prop('checked');

            if (Condition.conditionTypes.BOOL.test(conditionType)) {
                value = isChecked;
            } else if (Condition.conditionTypes.INT.test(conditionType)) {
                value = Number(isChecked);
            } else if (Condition.conditionTypes.STR.test(conditionType)) {
                value = (isChecked ? value : '');
            }
        }
        // Select: get the selected item's number
        else if ($el.get(0).nodeName === 'SELECT' && Condition.conditionTypes.INT.test(conditionType)) {
            // First get the proper selected radio button value.
            originalValue = $parents.find('option:selected').val();
            value = originalValue;

            $parents.find('select option').each(function (key, el) {
                values[$(el).val()] = key;
            });
        }

        // Map radio button/select option value to a numeric representation.
        if (Object.keys(values).length > 0) {
            value = values[originalValue];
        }

        return getParsedValue(conditionData, value);
    };

    /**
     * Prepare an element selector to select the element or depending input element(s).
     *
     * @param {string} elementIdentifier
     *
     * @return {string}
     */
    var prepareSelector = function prepareSelector(elementIdentifier) {
        var className = '.id_' + elementIdentifier;

        return [
            className,
            className + ' select',
            className + ' input',
        ].join(',');
    };

    /**
     * Check if target and condition value equal. The equal check type is delivered via the condition.
     *
     * @param {object} conditionData
     * @param {string|boolean|number|*} val
     *
     * @returns {boolean}
     */
    var isEqual = function isEqual(conditionData, val) {
        var result;

        var valueCondition = getParsedValue(conditionData, conditionData.compare.value);
        var valueTargetNode = getParsedValue(conditionData, val);

        switch (conditionData.type) {
            case equalTypes.GREATER:
                result = (valueTargetNode > valueCondition);
                break;

            case equalTypes.GREATER_THAN:
            case equalTypes.GT:
                result = (valueTargetNode >= valueCondition);
                break;

            case equalTypes.LESS:
                result = (valueTargetNode < valueCondition);
                break;

            case equalTypes.LESS_THAN:
            case equalTypes.LT:
                result = (valueTargetNode <= valueCondition);
                break;

            case equalTypes.NOT_EQ:
                result = (valueTargetNode !== valueCondition);
                break;

            case equalTypes.EQUAL:
            default:
                result = (valueTargetNode === valueCondition);
                break;
        }

        return result;
    };

    /**
     * Check if all conditions in a main condition object are met or not.
     *
     * @param {object} mainConditionObject
     *
     * @returns {boolean}
     */
    var isAllEqual = function isAllEqual(mainConditionObject) {
        var state = null;

        mainConditionObject.conditions.forEach(function (conditionData) {
            // console.group('check condition "' + conditionData.targetId + '"');

            try {
                // console.log('target selector', Condition.prepareSelector(conditionData.targetId), $(Condition.prepareSelector(conditionData.targetId)));
                var $targetElement = $('body')
                    .find(Condition.prepareSelector(conditionData.targetId))
                    .not(function (key, el) {
                        // Skip elements which are in the "verify data" block.
                        return $(el).parents('#block_verify_input_data').length === 1;
                    })
                    .last();
                // console.log('target element', $targetElement);
                var value = Condition.getInputValue($targetElement, conditionData);
                // console.log('value', value);
                // var parsedValue = Condition.getParsedValue(conditionData, value);
                // console.log('parsed value', parsedValue);
                // var parsedConditionValue = Condition.getParsedValue(conditionData, conditionData.compare.value);
                // console.log('parsed condition value', parsedConditionValue);
                var isConditionMet = Condition.isEqual(conditionData, value);
                // console.log('is condition met?', isConditionMet);

                if (state === null) {
                    state = isConditionMet;
                } else {
                    if (mainConditionObject.type === Condition.equalTypes.AND) {
                        state = Boolean(state & isConditionMet);
                    } else if (mainConditionObject.type === Condition.equalTypes.OR) {
                        state = Boolean(state | isConditionMet);
                    }
                }
            } catch (ex) {
                void 0;
            }

            // console.groupEnd();
        });

        return state;
    };

    return {
        equalTypes: equalTypes,
        conditionTypes: conditionTypes,
        parser: conditionTypeMapping,
        getParsedValue: getParsedValue,
        getInputValue: getInputValue,
        prepareSelector: prepareSelector,
        isEqual: isEqual,
        isAllEqual: isAllEqual,
    };
})($);


// Provide objects for unit testing on the cli.
if (typeof module !== 'undefined') {
    module.exports.Condition = Condition;
}
