/**
 *
 * @date   27.06.2017 11:05
 * @author Michael Raith <michael.raith@bcmsolutions.de>
 */

/* global $, module */

/**
 * Helper methods to fetch and parse payment information.
 *
 * @type {{refreshPaymentInfo, updateCreditCardFeeField, getCurrency, getCreditCardFee}}
 */
var PaymentHelper = (function ($, window) {
    'use strict';

    /**
     * Current payment info data requested from the backend.
     *
     * @type {{}}
     */
    var paymentInfoData = {};

    /**
     * Current rendered product listing.
     *
     * @type {string}
     */
    var productListingTemplate = '';

    /**
     * Define some selectors as "constants".
     *
     * @type {{form: string, productList: string, paymentTypeTemplate: string, cc: string, payment: string, paymentType: string}}
     */
    var selectors = {
        form: '.book-form',
        productList: '.product-list-wrapper',
        paymentTypeTemplate: '.payment-type-',
        cc: '.payment-type-K',
        payment: '#form_payment-type',
        paymentType: '#form_payment-type_type',
    };


    /**
     * Get the current active payment type.
     *
     * @returns {string} Values: "X" (billing), "Y" (direct debit), "K" (credit card)
     */
    var getActivePaymentType = function () {
        return $(selectors.paymentType).val();
    };

    /**
     * Get the current active payment type wrapper element.
     *
     * @returns {jQuery|HTMLElement|*}
     */
    var getActivePaymentElement = function () {
        return $(selectors.paymentTypeTemplate + getActivePaymentType());
    };

    /**
     * Get the current credit card payment fee calculated by the backend.
     *
     * @param {object} [options={raw: false}] Pass additional options:
     *                                        - raw: Get raw value or prepared value for current locale settings.
     *
     * @returns {number|float}
     */
    var getCreditCardFee = function (options) {
        options = (options || {});
        options.raw = (options.raw || false);

        return paymentInfoData['credit-card-fee-value' + (options.raw ? '-raw' : '')];
    };

    /**
     * Get the current currency.
     *
     * @returns {string}
     */
    var getCurrency = function () {
        return paymentInfoData['currency'];
    };

    /**
     * Update the credit card fee value in the form.
     */
    var updateCreditCardFeeField = function () {
        var $feeElement = $(selectors.form).find(selectors.cc);

        $feeElement.find('.payment-fee--value').text(getCreditCardFee());
        $feeElement.find('.payment-fee--currency').text(getCurrency());
    };

    /**
     * Print out updated product listing.
     * This method will automatically hide the loading spinner added by "displayLoadingSpinner".
     */
    var updateProductList = function () {
        $(selectors.form).find(selectors.productList).html(productListingTemplate);
    };

    /**
     * Remove old product listing and display a loading spinner.
     */
    var displayLoadingSpinner = function () {
        // Empty target element first and add loading spinner to it.
        $(selectors.form)
            .find(selectors.productList)
            .empty()
            .append(window.LoadingSpinner.getLoadingSpinner());
    };

    /**
     * Get a url stored in the form's data attribute.
     *
     * @param {string} urlName
     *
     * @returns {string}
     */
    var getUrl = function (urlName) {
        // console.log('url "%s" for "%s"', $(selectors.form).data(urlName), urlName);
        return $(selectors.form).data(urlName);
    };

    /**
     * Get a fresh copy of the current payment information prepared by the backend.
     *
     * @return {Promise}
     */
    var refreshPaymentInfo = function () {
        displayLoadingSpinner();

        // RequestHelper.postForm(url, $(selectors.form).get(0))
        return window.RequestHelper.postForm(getUrl('payment-info-data-url'), $(selectors.form).get(0))
            .then(function (response) {
                // console.log('got fetch api response', response);

                if (response && response.data && response.template) {
                    paymentInfoData = response.data;
                    productListingTemplate = response.template;

                    PaymentHelper.updateCreditCardFeeField();
                    PaymentHelper.updateProductList();
                } else {
                    void 0;
                }
            });
    };

    /**
     * Validate payment form and replace it on errors to display form errors.
     *
     * @returns {Promise.<TResult>}
     */
    var validatePayment = function () {
        return window.RequestHelper.postForm(getUrl('validate-payment-data-url'), $(selectors.form).get(0))
            .then(function (response) {
                // console.log('got fetch api response', response);

                if (response && response.errors && response.errors.length > 0) {
                    void 0;

                    return Promise.reject(response.errors);
                } else if (response && response.data) {
                    $(selectors.payment).html(
                        $(response.data)
                            .find(selectors.payment)
                            .html()
                    );
                } else {
                    void 0;
                }
            });
    };

    return {
        getActivePaymentType: getActivePaymentType,
        getActivePaymentElement: getActivePaymentElement,
        getUrl: getUrl,
        refreshPaymentInfo: refreshPaymentInfo,
        updateCreditCardFeeField: updateCreditCardFeeField,
        updateProductList: updateProductList,
        getCurrency: getCurrency,
        getCreditCardFee: getCreditCardFee,
        validatePayment: validatePayment,
    };
})($, window);


// Provide objects for unit testing on the cli.
if (typeof module !== 'undefined') {
    module.exports.PaymentHelper = PaymentHelper;
}
