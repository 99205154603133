/**
 *
 * @date   20.06.2017 13:51
 * @author Michael Raith <michael.raith@bcmsolutions.de>
 */

/* global module, window, Promise */

/**
 * Helper methods for using the browser's fetch api.
 *
 * @type {{get, post, postJson, postForm}}
 */
var RequestHelper = (function (window) {
    'use strict';

    /**
     * Handle json response.
     *
     * @param response
     *
     * @returns {*|Promise.<T>}
     */
    var handleJsonResponse = function handleJsonResponse(response) {
        return response.json()
            .then(function (json) {
                if (response.ok) {
                    return json
                } else {
                    return Promise.reject(Object.assign({}, json, {
                        status: response.status,
                        statusText: response.statusText
                    }));
                }
            });
    };

    /**
     * Handle text response.
     *
     * @param {object} response
     *
     * @returns {*|Promise.<T>}
     */
    var handleTextResponse = function (response) {
        return response.text()
            .then(function (text) {
                if (response.ok) {
                    return text;
                } else {
                    return Promise.reject({
                        status: response.status,
                        statusText: response.statusText,
                        err: text
                    });
                }
            });
    };

    /**
     * Handle response depending on its content type.
     *
     * @param {object} response
     *
     * @returns {*|Promise.<T>}
     */
    var handleResponse = function handleResponse(response) {
        var contentType = response.headers.get('content-type');

        if (contentType && contentType.includes('application/json')) {
            return handleJsonResponse(response);
        } else if (contentType && contentType.includes('text/html')) {
            return handleTextResponse(response);
        } else {
            // Other response types as necessary. I haven't found a need for them yet though.
            return Promise.reject('Sorry, content-type "' + contentType + '" not supported');
        }
    };

    /**
     * Handle fetch api errors.
     *
     * @param {object|Error} err
     */
    var handleErrors = function (err) {
        window.LoadingSpinner.hideModalLoader();
        void 0;

        // Re-throw error so the application can handle it too.
        return Promise.reject(err);
    };

    /**
     * Trigger a get request using fetch api.
     *
     * @param {string} url
     * @param {object} headers
     *
     * @returns {*|Promise.<T>}
     */
    var getRequest = function (url, headers) {
        if (!headers) {
            headers = {};
        }

        return window
            .fetch(url, {
                method: 'get',
                headers: Object.assign(
                    {'X-Requested-With': 'XMLHttpRequest'},
                    headers
                )
            })
            .then(handleResponse)
            .catch(handleErrors);
    };

    /**
     * Trigger a post request using fetch api.
     *
     * @param {string}      url
     * @param {string|null} data
     * @param {object}      headers
     *
     * @returns {*|Promise.<T>}
     */
    var postRequest = function (url, data, headers) {
        if (!headers) {
            headers = {};
        }

        return window
            .fetch(url, {
                method: 'post',
                headers: Object.assign(
                    {'X-Requested-With': 'XMLHttpRequest'},
                    headers
                ),
                body: data,

                /*
                 * Enable cookie support which is disabled by default. We need this to pass the current session cookie
                 * to validate the form's csrf token.
                 *
                 * @see http://stackoverflow.com/questions/34558264/fetch-api-with-cookie
                 */
                credentials: 'same-origin',
                // On cross origin sites, you might want to use this one.
                //credentials: 'include',
            })
            .then(handleResponse)
            .catch(handleErrors);
    };

    /**
     * Trigger a post request with json payload using fetch api.
     *
     * @param {string}   url
     * @param {object|*} jsonData
     * @param {object}   headers
     *
     * @returns {*|Promise.<T>}
     */
    var postJsonRequest = function (url, jsonData, headers) {
        if (!headers) {
            headers = {};
        }

        var body;
        if (jsonData) {
            body = JSON.stringify(jsonData);
        }

        return postRequest(
            url,
            body,
            Object.assign(
                {'Content-Type': 'application/json'},
                headers
            )
        )
    };

    /**
     * Trigger a post request with form data payload using fetch api.
     *
     * @param {string}      url
     * @param {HTMLElement} formElement
     * @param {object}      headers
     *
     * @returns {*|Promise.<T>}
     */
    var postFormRequest = function (url, formElement, headers) {
        if (!headers) {
            headers = {};
        }

        var formData;
        if (formElement) {
            formData = new FormData(formElement);
        }

        return postRequest(
            url,
            formData,
            Object.assign(
                {'mimeType': 'multipart/form-data'},
                headers
            )
        )
    };

    /**
     *
     * @param {FormData}    formData
     * @param {HTMLElement} element
     *
     * @return {FormData}
     */
    var appendElementToFormData = function (formData, element) {
        formData.append(element.name, element.value);

        return formData;
    };

    /**
     * Trigger a post request with a list of form element's data payload using fetch api.
     *
     * @param {string}             url
     * @param {Array<HTMLElement>} formElements
     * @param {object}             headers
     *
     * @returns {*|Promise.<T>}
     */
    var postFormElementsRequest = function (url, formElements, headers) {
        if (!headers) {
            headers = {};
        }

        var formData;
        if (formElements && formElements.length > 0) {
            formData = new FormData();

            for (var i = 0, len = formElements.length; i < len; i++) {
                var formElement = formElements[i];

                if (!(formElement instanceof HTMLElement)) {
                    continue;
                }

                if (/^input|textarea|select$/i.test(formElement.nodeName)) {
                    formData = appendElementToFormData(formData, formElement);
                } else {
                    formElement.querySelectorAll('input, textarea, select').forEach(function (el) {
                        formData = appendElementToFormData(formData, el);
                    });
                }
            }
        }

        return postRequest(
            url,
            formData,
            Object.assign(
                {'mimeType': 'multipart/form-data'},
                headers
            )
        )
    };

    return {
        get: getRequest,
        post: postRequest,
        postJson: postJsonRequest,
        postForm: postFormRequest,
        postFormElements: postFormElementsRequest
    };
})(window);


// Provide objects for unit testing on the cli.
if (typeof module !== 'undefined') {
    module.exports.RequestHelper = RequestHelper;
}
