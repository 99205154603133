/**
 *
 * @date   20.06.2017 13:51
 * @author Michael Raith <michael.raith@bcmsolutions.de>
 */

/* global $, module, window */

/**
 * Helper methods to manipulate form elements.
 *
 * @type {{Textarea, Validator}}
 */
var FormElement = (function ($) {
    'use strict';

    /**
     * Update a textareas word counter.
     *
     * @param {Event} e
     */
    var updateWordCount = function updateWordCount(e) {
        var $el = $(e.target);

        if (!$el || $el.length === 0 || !($el[0] instanceof HTMLElement)) {
            return;
        }

        var value = $el.val();
        var characterCount = value.length;
        var maxLength = $el.prop('maxlength');

        // Trim overlong text in case the attribute "maxlength" is not interpreted by any browser.
        if (maxLength > 0 && characterCount > maxLength) {
            characterCount = maxLength;

            $el.val(value.substr(0, characterCount));
        }

        // Update character counter under textarea element.
        $el.parents('.form-group').find('.word-count').text(characterCount);
    };


    /**
     * Helper method to validate a value + country information against a mapping.
     *
     * @param {object} mapping
     * @param {string} value
     * @param {string} country
     *
     * @returns {boolean}
     */
    var validateValueAgainstMapping = function (mapping, value, country) {
        // Invalid input value? Skip validator.
        if ((typeof value !== 'string') || String(value).length < 1 || !country || String(country).length !== 2) {
            return false;
        }

        country = country.toUpperCase();

        // No regex for the passed country available? Return true and just accept the value.
        if (typeof mapping[country] === 'undefined') {
            return true;
        }

        // Validate zip code for the passed country.
        return mapping[country].test(value.trim());
    };


    /**
     * A mapping of all countries to their zip code regex for validating zip code inputs.
     * Currently only DE/AT/CH countries are enabled, because we probably won't need the others so often ...
     *
     * @type {{DE: RegExp, AT: RegExp, CH: RegExp}}
     */
    var countryToZipCodeMapping = {
        'DE': /^\d{5}$/i,
        'AT': /^\d{4}$/i,
        'CH': /^\d{4}$/i,
        // 'GB': /^GIR[ ]?0AA|((AB|AL|B|BA|BB|BD|BH|BL|BN|BR|BS|BT|CA|CB|CF|CH|CM|CO|CR|CT|CV|CW|DA|DD|DE|DG|DH|DL|DN|DT|DY|E|EC|EH|EN|EX|FK|FY|G|GL|GY|$GU|HA|HD|HG|HP|HR|HS|HU|HX|IG|IM|IP|IV|JE|KA|KT|KW|KY|L|LA|LD|LE|LL|LN|LS|LU|M|ME|MK|ML|N|NE|NG|NN|NP|NR|NW|OL|OX|PA|PE|PH|PL|PO|PR|RG|RH|RM|S|SA|SE|SG|SK|SL|SM|SN|SO|SP|SR|SS|ST|SW|SY|TA|TD|TF|TN|TQ|TR|TS|TW|UB|W|WA|WC|WD|WF|WN|WR|WS|WV|YO|ZE)(\d[\dA-Z]?[ ]?\d[ABD-HJLN-UW-Z]{2}))|BFPO[ ]?\d{1,4}/i,
        // 'JE': /^JE\d[\dA-Z]?[ ]?\d[ABD-HJLN-UW-Z]{2}$/i,
        // 'GG': /^GY\d[\dA-Z]?[ ]?\d[ABD-HJLN-UW-Z]{2}$/i,
        // 'IM': /^IM\d[\dA-Z]?[ ]?\d[ABD-HJLN-UW-Z]{2}$/i,
        // 'US': /^\d{5}([ \-]\d{4})?$/i,
        // 'CA': /^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ ]?\d[ABCEGHJ-NPRSTV-Z]\d$/i,
        // 'JP': /^\d{3}-\d{4}$/i,
        // 'FR': /^\d{2}[ ]?\d{3}$/i,
        // 'AU': /^\d{4}$/i,
        // 'IT': /^\d{5}$/i,
        // 'ES': /^\d{5}$/i,
        // 'NL': /^\d{4}[ ]?[A-Z]{2}$/i,
        // 'BE': /^\d{4}$/i,
        // 'DK': /^\d{4}$/i,
        // 'SE': /^\d{3}[ ]?\d{2}$/i,
        // 'NO': /^\d{4}$/i,
        // 'BR': /^\d{5}[\-]?\d{3}$/i,
        // 'PT': /^\d{4}([\-]\d{3})?$/i,
        // 'FI': /^\d{5}$/i,
        // 'AX': /^22\d{3}$/i,
        // 'KR': /^\d{3}[\-]\d{3}$/i,
        // 'CN': /^\d{6}$/i,
        // 'TW': /^\d{3}(\d{2})?$/i,
        // 'SG': /^\d{6}$/i,
        // 'DZ': /^\d{5}$/i,
        // 'AD': /^AD\d{3}$/i,
        // 'AR': /^([A-HJ-NP-Z])?\d{4}([A-Z]{3})?$/i,
        // 'AM': /^(37)?\d{4}$/i,
        // 'AZ': /^\d{4}$/i,
        // 'BH': /^((1[0-2]|[2-9])\d{2})?$/i,
        // 'BD': /^\d{4}$/i,
        // 'BB': /^(BB\d{5})?$/i,
        // 'BY': /^\d{6}$/i,
        // 'BM': /^[A-Z]{2}[ ]?[A-Z0-9]{2}$/i,
        // 'BA': /^\d{5}$/i,
        // 'IO': /^BBND 1ZZ$/i,
        // 'BN': /^[A-Z]{2}[ ]?\d{4}$/i,
        // 'BG': /^\d{4}$/i,
        // 'KH': /^\d{5}$/i,
        // 'CV': /^\d{4}$/i,
        // 'CL': /^\d{7}$/i,
        // 'CR': /^\d{4,5}|\d{3}-\d{4}$/i,
        // 'HR': /^\d{5}$/i,
        // 'CY': /^\d{4}$/i,
        // 'CZ': /^\d{3}[ ]?\d{2}$/i,
        // 'DO': /^\d{5}$/i,
        // 'EC': /^([A-Z]\d{4}[A-Z]|(?:[A-Z]{2})?\d{6})?$/i,
        // 'EG': /^\d{5}$/i,
        // 'EE': /^\d{5}$/i,
        // 'FO': /^\d{3}$/i,
        // 'GE': /^\d{4}$/i,
        // 'GR': /^\d{3}[ ]?\d{2}$/i,
        // 'GL': /^39\d{2}$/i,
        // 'GT': /^\d{5}$/i,
        // 'HT': /^\d{4}$/i,
        // 'HN': /^(?:\d{5})?$/i,
        // 'HU': /^\d{4}$/i,
        // 'IS': /^\d{3}$/i,
        // 'IN': /^\d{6}$/i,
        // 'ID': /^\d{5}$/i,
        // 'IL': /^\d{5}$/i,
        // 'JO': /^\d{5}$/i,
        // 'KZ': /^\d{6}$/i,
        // 'KE': /^\d{5}$/i,
        // 'KW': /^\d{5}$/i,
        // 'LA': /^\d{5}$/i,
        // 'LV': /^\d{4}$/i,
        // 'LB': /^(\d{4}([ ]?\d{4})?)?$/i,
        // 'LI': /^(948[5-9])|(949[0-7])$/i,
        // 'LT': /^\d{5}$/i,
        // 'LU': /^\d{4}$/i,
        // 'MK': /^\d{4}$/i,
        // 'MY': /^\d{5}$/i,
        // 'MV': /^\d{5}$/i,
        // 'MT': /^[A-Z]{3}[ ]?\d{2,4}$/i,
        // 'MU': /^(\d{3}[A-Z]{2}\d{3})?$/i,
        // 'MX': /^\d{5}$/i,
        // 'MD': /^\d{4}$/i,
        // 'MC': /^980\d{2}$/i,
        // 'MA': /^\d{5}$/i,
        // 'NP': /^\d{5}$/i,
        // 'NZ': /^\d{4}$/i,
        // 'NI': /^((\d{4}-)?\d{3}-\d{3}(-\d{1})?)?$/i,
        // 'NG': /^(\d{6})?$/i,
        // 'OM': /^(PC )?\d{3}$/i,
        // 'PK': /^\d{5}$/i,
        // 'PY': /^\d{4}$/i,
        // 'PH': /^\d{4}$/i,
        // 'PL': /^\d{2}-\d{3}$/i,
        // 'PR': /^00[679]\d{2}([ \-]\d{4})?$/i,
        // 'RO': /^\d{6}$/i,
        // 'RU': /^\d{6}$/i,
        // 'SM': /^4789\d$/i,
        // 'SA': /^\d{5}$/i,
        // 'SN': /^\d{5}$/i,
        // 'SK': /^\d{3}[ ]?\d{2}$/i,
        // 'SI': /^\d{4}$/i,
        // 'ZA': /^\d{4}$/i,
        // 'LK': /^\d{5}$/i,
        // 'TJ': /^\d{6}$/i,
        // 'TH': /^\d{5}$/i,
        // 'TN': /^\d{4}$/i,
        // 'TR': /^\d{5}$/i,
        // 'TM': /^\d{6}$/i,
        // 'UA': /^\d{5}$/i,
        // 'UY': /^\d{5}$/i,
        // 'UZ': /^\d{6}$/i,
        // 'VA': /^00120$/i,
        // 'VE': /^\d{4}$/i,
        // 'ZM': /^\d{5}$/i,
        // 'AS': /^96799$/i,
        // 'CC': /^6799$/i,
        // 'CK': /^\d{4}$/i,
        // 'RS': /^\d{6}$/i,
        // 'ME': /^8\d{4}$/i,
        // 'CS': /^\d{5}$/i,
        // 'YU': /^\d{5}$/i,
        // 'CX': /^6798$/i,
        // 'ET': /^\d{4}$/i,
        // 'FK': /^FIQQ 1ZZ$/i,
        // 'NF': /^2899$/i,
        // 'FM': /^(9694[1-4])([ \-]\d{4})?$/i,
        // 'GF': /^9[78]3\d{2}$/i,
        // 'GN': /^\d{3}$/i,
        // 'GP': /^9[78][01]\d{2}$/i,
        // 'GS': /^SIQQ 1ZZ$/i,
        // 'GU': /^969[123]\d([ \-]\d{4})?$/i,
        // 'GW': /^\d{4}$/i,
        // 'HM': /^\d{4}$/i,
        // 'IQ': /^\d{5}$/i,
        // 'KG': /^\d{6}$/i,
        // 'LR': /^\d{4}$/i,
        // 'LS': /^\d{3}$/i,
        // 'MG': /^\d{3}$/i,
        // 'MH': /^969[67]\d([ \-]\d{4})?$/i,
        // 'MN': /^\d{6}$/i,
        // 'MP': /^9695[012]([ \-]\d{4})?$/i,
        // 'MQ': /^9[78]2\d{2}$/i,
        // 'NC': /^988\d{2}$/i,
        // 'NE': /^\d{4}$/i,
        // 'VI': /^008(([0-4]\d)|(5[01]))([ \-]\d{4})?$/i,
        // 'PF': /^987\d{2}$/i,
        // 'PG': /^\d{3}$/i,
        // 'PM': /^9[78]5\d{2}$/i,
        // 'PN': /^PCRN 1ZZ$/i,
        // 'PW': /^96940$/i,
        // 'RE': /^9[78]4\d{2}$/i,
        // 'SH': /^(ASCN|STHL) 1ZZ$/i,
        // 'SJ': /^\d{4}$/i,
        // 'SO': /^\d{5}$/i,
        // 'SZ': /^[HLMS]\d{3}$/i,
        // 'TC': /^TKCA 1ZZ$/i,
        // 'WF': /^986\d{2}$/i,
        // 'XK': /^\d{5}$/i,
        // 'YT': /^976\d{2$}/i
    };

    /**
     * Validate a zip code depending on its country.
     *
     * @param {string|number} value
     * @param {string}        country
     *
     * @returns {boolean}
     */
    var validateZipCode = function validateZipCode(value, country) {
        return validateValueAgainstMapping(countryToZipCodeMapping, value, country);
    };


    /**
     * A mapping of all eu countries to their tax id number.
     *
     * @type {{RegExp}}
     *
     * @see http://ec.europa.eu/taxation_customs/vies/faq.html#item_11
     * @see https://stackoverflow.com/questions/33625770/check-vat-number-for-syntactical-correctness-with-regex-possible
     * @see https://ipsec.pl/data-protection/2012/european-personal-data-regexp-patterns.html
     */
    var countryToTaxIdNumberMapping = {
        'AT': /^(AT)?U[0-9]{8}$/i,                              //  Austria
        'BE': /^(BE)?0[0-9]{9}$/i,                              //  Belgium
        'BG': /^(BG)?[0-9]{9,10}$/i,                            //  Bulgaria
        'HR': /^(HR)?[0-9]{11}$/i,                              //  Croatia
        'CY': /^(CY)?[0-9]{8}L$/i,                              //  Cyprus
        'CZ': /^(CZ)?[0-9]{8,10}$/i,                            //  Czech Republic
        //'CH': /^(CHE)?([0-9]{3}\.?[0-9]{2}\.?[0-9]{3}\.?[0-9]{3})|(756\.?[0-9]{4}\.?[0-9]{4}\.?[0-9]{2})/i, // Switzerland (old format, new format since 2008)
        'CH': /^(CHE)[- .]?([0-9]{3}?[ .]?[0-9]{3}?[ .]?[0-9]{3})/i,
        'DE': /^(DE)?[0-9]{9}$/i,                               //  Germany
        'DK': /^(DK)?[0-9]{8}$/i,                               //  Denmark
        'EE': /^(EE)?[0-9]{9}$/i,                               //  Estonia
        'EL': /^(EL)?[0-9]{9}$/i,                               //  Greece
        'ES': /^ES[A-Z][0-9]{7}(?:[0-9]|[A-Z])$/i,              //  Spain
        'FI': /^(FI)?[0-9]{8}$/i,                               //  Finland
        'FR': /^(FR)?[0-9A-Z]{2}[0-9]{9}$/i,                    //  France
        'GB': /^(GB)?([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})$/i, //  United Kingdom
        'HU': /^(HU)?[0-9]{8}$/i,                               //  Hungary
        'IE': /^(IE)?[0-9]S[0-9]{5}L$/i,                        //  Ireland
        'IT': /^(IT)?[0-9]{11}$/i,                              //  Italy
        'LT': /^(LT)?([0-9]{9}|[0-9]{12})$/i,                   //  Lithuania
        'LU': /^(LU)?[0-9]{8}$/i,                               //  Luxembourg
        'LV': /^(LV)?[0-9]{11}$/i,                              //  Latvia
        'MT': /^(MT)?[0-9]{8}$/i,                               //  Malta
        'NL': /^(NL)?[0-9]{9}B[0-9]{2}$/i,                      //  Netherlands
        'PL': /^(PL)?[0-9]{10}$/i,                              //  Poland
        'PT': /^(PT)?[0-9]{9}$/i,                               //  Portugal
        'RO': /^(RO)?[0-9]{2,10}$/i,                            //  Romania
        'SE': /^(SE)?[0-9]{12}$/i,                              //  Sweden
        'SI': /^(SI)?[0-9]{8}$/i,                               //  Slovenia
        'SK': /^(SK)?[0-9]{10}$/i,                              //  Slovakia
    };

    /**
     * Validate a tax id number depending on its country.
     *
     * @param {string} value
     * @param {string} country
     *
     * @returns {boolean}
     */
    var validateTaxIdNumber = function validateTaxIdNumber(value, country) {
        return validateValueAgainstMapping(countryToTaxIdNumberMapping, value, country);
    };


    /**
     * Check if the checksum if correct.
     *
     * @param {string} value
     *
     * @returns {boolean}
     *
     * @see http://ht5ifv.serprest.pt/extensions/tools/IBAN/
     */
    var isValidIBAN = function (value) {
        // Move the first 4 chars from left to the right.
        value = value.replace(/^(.{4})(.*)$/, '$2$1');
        // Convert A-Z to 10-25.
        value = value.replace(/[A-Z]/g, function ($e) {
            return $e.charCodeAt(0) - 'A'.charCodeAt(0) + 10
        });

        var $sum = 0;
        // First exponent
        var $ei = 1;

        for (var $i = value.length - 1; $i >= 0; $i--) {
            // Multiply the digit by it's exponent.
            $sum += $ei * parseInt(value.charAt($i), 10);

            // Compute next base 10 exponent in modulus 97.
            $ei = ($ei * 10) % 97;
        }

        return ($sum % 97) === 1;
    };

    /**
     *
     * @type {object}
     *
     * @see http://ht5ifv.serprest.pt/extensions/tools/IBAN/
     * @see http://www.rbs.co.uk/corporate/international/g0/guide-to-international-business/regulatory-information/iban/iban-example.ashx
     */
    var countryToIbanMapping = {
        'AL': /^AL\d{10}[0-9A-Z]{16}$/,
        'AD': /^AD\d{10}[0-9A-Z]{12}$/,
        'AT': /^AT\d{18}$/,
        'BH': /^BH\d{2}[A-Z]{4}[0-9A-Z]{14}$/,
        'BE': /^BE\d{14}$/,
        'BA': /^BA\d{18}$/,
        'BG': /^BG\d{2}[A-Z]{4}\d{6}[0-9A-Z]{8}$/,
        'HR': /^HR\d{19}$/,
        'CY': /^CY\d{10}[0-9A-Z]{16}$/,
        'CZ': /^CZ\d{22}$/,
        'DK': /^DK\d{16}$|^FO\d{16}$|^GL\d{16}$/,
        'DO': /^DO\d{2}[0-9A-Z]{4}\d{20}$/,
        'EE': /^EE\d{18}$/,
        'FI': /^FI\d{16}$/,
        'FR': /^FR\d{12}[0-9A-Z]{11}\d{2}$/,
        'GE': /^GE\d{2}[A-Z]{2}\d{16}$/,
        'DE': /^DE\d{20}$/,
        'GI': /^GI\d{2}[A-Z]{4}[0-9A-Z]{15}$/,
        'GR': /^GR\d{9}[0-9A-Z]{16}$/,
        'HU': /^HU\d{26}$/,
        'IS': /^IS\d{24}$/,
        'IE': /^IE\d{2}[A-Z]{4}\d{14}$/,
        'IL': /^IL\d{21}$/,
        'IT': /^IT\d{2}[A-Z]\d{10}[0-9A-Z]{12}$/,
        'KZ': /^[A-Z]{2}\d{5}[0-9A-Z]{13}$/,
        'KW': /^KW\d{2}[A-Z]{4}\d{22}$/,
        'LV': /^LV\d{2}[A-Z]{4}[0-9A-Z]{13}$/,
        'LB': /^LB\d{6}[0-9A-Z]{20}$/,
        'LI': /^LI\d{7}[0-9A-Z]{12}$/,
        'LT': /^LT\d{18}$/,
        'LU': /^LU\d{5}[0-9A-Z]{13}$/,
        'MK': /^MK\d{5}[0-9A-Z]{10}\d{2}$/,
        'MT': /^MT\d{2}[A-Z]{4}\d{5}[0-9A-Z]{18}$/,
        'MR': /^MR13\d{23}$/,
        'MU': /^MU\d{2}[A-Z]{4}\d{19}[A-Z]{3}$/,
        'MC': /^MC\d{12}[0-9A-Z]{11}\d{2}$/,
        'ME': /^ME\d{20}$/,
        'NL': /^NL\d{2}[A-Z]{4}\d{10}$/,
        'NO': /^NO\d{13}$/,
        'PL': /^PL\d{10}[0-9A-Z]{16}$/,
        'PT': /^PT\d{23}$/,
        'RO': /^RO\d{2}[A-Z]{4}[0-9A-Z]{16}$/,
        'SM': /^SM\d{2}[A-Z]\d{10}[0-9A-Z]{12}$/,
        'SA': /^SA\d{4}[0-9A-Z]{18}$/,
        'RS': /^RS\d{20}$/,
        'SK': /^SK\d{22}$/,
        'SI': /^SI\d{17}$/,
        'ES': /^ES\d{22}$/,
        'SE': /^SE\d{22}$/,
        'CH': /^CH\d{7}[0-9A-Z]{12}$/,
        'TN': /^TN59\d{20}$/,
        'TR': /^TR\d{7}[0-9A-Z]{17}$/,
        'AE': /^AE\d{21}$/,
        'GB': /^GB\d{2}[A-Z]{4}\d{14}$/,
    };

    /**
     * Validate a IBAN depending on its country.
     *
     * @param {string} value
     * @param {string} country
     *
     * @returns {boolean}
     */
    var validateIBAN = function validateIBAN(value, country) {
        if (typeof value === 'string') {
            // Strip off optional whitespaces between groups.
            value = (value.replace(/[ ]/g, ''));
            // Convert IBAN always into upper case characters.
            value = value.toUpperCase();

            // No regex for country found? Try to validate IBAN by its checksum if country is not defined in our list.
            if (typeof countryToIbanMapping[country] === 'undefined') {
                return isValidIBAN(value);
            }

            return validateValueAgainstMapping(countryToIbanMapping, value, country) && isValidIBAN(value);
        }

        return false;
    };

    /**
     * Validate a BIC/SWIFT.
     *
     * @param {string} value
     *
     * @return {boolean}
     */
    var validateBIC = function validateBIC(value) {
        if (typeof value !== 'string' || String(value).length < 1) {
            return false;
        }

        // Works
        return /^([a-zA-Z]){4}([a-zA-Z]){2}([0-9a-zA-Z]){2}([0-9a-zA-Z]{3})?$/.test(value);

        // Does not work in all cases.
        // return /^([a-zA-Z]){4}(AF|AX|AL|DZ|AS|AD|AO|AI|AQ|AG|AR|AM|AW|AU|AZ|BS|BH|BD|BB|BY|BE|BZ|BJ|BM|BT|BO|BA|BW|BV|BR|IO|BN|BG|BF|BI|KH|CM|CA|CV|KY|CF|TD|CL|CN|CX|CC|CO|KM|CG|CD|CK|CR|CI|HR|CU|CY|CZ|DK|DJ|DM|DO|EC|EG|SV|GQ|ER|EE|ET|FK|FO|FJ|FI|FR|GF|PF|TF|GA|GM|GE|DE|GH|GI|GR|GL|GD|GP|GU|GT|GG|GN|GW|GY|HT|HM|VA|HN|HK|HU|IS|IN|ID|IR|IQ|IE|IM|IL|IT|JM|JP|JE|JO|KZ|KE|KI|KP|KR|KW|KG|LA|LV|LB|LS|LR|LY|LI|LT|LU|MO|MK|MG|MW|MY|MV|ML|MT|MH|MQ|MR|MU|YT|MX|FM|MD|MC|MN|ME|MS|MZ|MM|MA|NR|NP|NL|AN|NC|NZ|NI|NE|NG|NU|NF|MP|NO|OM|PK|PW|PS|PA|PG|PY|PE|PH|PN|PL|PT|PR|QA|RE|RO|RU|RW|SH|KN|LC|PM|VC|WS|SM|ST|SA|SN|RS|SC|SL|SG|SK|SI|SB|SO|ZA|GS|ES|LK|SD|SR|SJ|SZ|SE|CH|SY|TW|TJ|TZ|TH|TL|TG|TK|TO|TT|TN|TR|TM|TC|TV|UG|UA|AE|GB|US|UM|UY|UZ|VU|VE|VN|VG|VI|WF|EH|YE|ZM|ZW)([0-9a-zA-Z]){2}([0-9a-zA-Z]{3})$/.test(value);
    };


    /**
     * Add a leading zero to numbers less than 10.
     *
     * @param {string} val
     *
     * @returns {string}
     */
    var prepareDateValue = function (val) {
        return (val < 10 ? '0' + val : val);
    };

    /**
     * Validate a birthday field consisting of multiple form fields (day, month and year).
     *
     * @param {*}      value       Value doesn't contain anything.
     * @param {*}      requirement
     * @param {object} instance    Parsley form element instance.
     *
     * @returns {boolean}
     */
    var validateBirthday = function validateBirthday(value, requirement, instance) {
        // console.group('validate birthday');
        // console.log('arguments', value, requirement, instance);

        var $element = instance.$element;
        // console.log('form element', $element);
        var attributeId = $element.prop('id');
        var isValid = false;

        // #1: First check if the value is in a valid range.
        if (attributeId.indexOf('birthday_day') !== -1) {
            // console.log('validate day');
            isValid = (value >= 1 && value <= 31);
        }
        else if (attributeId.indexOf('birthday_month') !== -1) {
            // console.log('validate month');
            isValid = (value >= 1 && value <= 12);
        }
        else if (attributeId.indexOf('birthday_year') !== -1) {
            // console.log('validate year');
            isValid = (value).toString().length === 4 && value <= (new Date()).getFullYear();
        }

        // #2: Skip if field value is not in a plausible range.
        if (!isValid) {
            // console.groupEnd();
            return isValid;
        }

        // #3: Get the birthday element's parent and check all child values in combination.
        var $elements = instance.$element.parents('.form-group').find('input, select');
        // console.log('birthday form elements', $elements);

        // This validator works only for a birthday field consisting of three fields.
        if ($elements.length !== 3) {
            // console.warn('Birthday form group does not contain 3 child elements!', $element);
            // console.groupEnd();
            return true;
        }

        // #4: Get day, month and year values.
        var dayValue = parseInt($elements.filter('[id*="day"]').val(), 10);
        var monthValue = parseInt($elements.filter('[id*="month"]').val(), 10);
        var yearValue = parseInt($elements.filter('[id*="year"]').val(), 10);
        // console.log('date values:\n> day: %s\n> month: %s\n> year: %s', dayValue, monthValue, yearValue);

        // Continue only if all three values are set.
        if (!dayValue || !monthValue || !yearValue) {
            // console.groupEnd();
            return true;
        }

        var dateString = [yearValue, prepareDateValue(monthValue), prepareDateValue(dayValue)].join('-');
        // console.log('dateString: "%s"', dateString);
        var date = new Date(dateString);

        // console.log('date', date);
        // console.log('date values:\n> day: %s\n> month: %s\n> year: %s', date.getDate(), (date.getMonth() + 1), date.getFullYear());

        // Check if date is valid and if date values equal the original values. We need this strange check to check for values in february greater 28.2./29.2.
        isValid = ((date).toString() !== 'Invalid Date' && dayValue === date.getDate() && monthValue === (date.getMonth() + 1) && yearValue === date.getFullYear());
        // console.log('is valid birthday', isValid);
        // console.groupEnd();

        if (isValid) {
            // Retrigger form validation on all other depending form elements.
            $elements.each(function (i, el) {
                var $el = $(el);

                if ($el.prop('id') !== attributeId) {
                    // Manually mark all other fields as "valid".
                    // Don't retrigger "isValid" or "validate" as this will cause a recursive loop.
                    $el
                        .removeClass('parsley-error').addClass('parsley-success')
                        .nextAll('.parsley-errors-list')
                        .removeClass('filled')
                        .children().remove();
                }
            });

            return isValid;
        }
        else {
            var locale = window.Parsley._validatorRegistry.locale;
            var invalidDateErrorMessage = {
                en: 'Invalid date. Please check your input.',
                de: 'Ungültiges Datum. Übrprüfen Sie ihre Eingabe.'
            };

            // NOTICE: we need to create a promise to reject this validator with a custom error message.
            var promise = new $.Deferred();
            return promise.reject(!invalidDateErrorMessage[locale] ? invalidDateErrorMessage.en : invalidDateErrorMessage[locale]);
        }
    };

    /**
     * Validate a file size input field.
     *
     * @param {*}      value       Value doesn't contain anything.
     * @param {*}      requirement
     * @param {object} instance    Parsley form element instance.
     *
     * @returns {boolean}
     */
    var validateMaxFileSize = function validateMaxFileSize(value, requirement, instance) {
        var files = instance.$element[0].files;
        return (files.length !== 1  || files[0].size <= requirement * 1024);
    };

    /**
     * Validate a file allowed type.
     *
     * @param {*}      value       Value doesn't contain anything.
     * @param {*}      requirement
     * @param {object} instance    Parsley form element instance.
     *
     * @returns {boolean}
     */
    var validateFileMimeType = function validateFileMimeType(value, requirement, instance) {
        var file = instance.$element[0].files;

        void 0;

        if (file.length === 0) {
            return true;
        }

        var allowedMimeTypes = requirement.replace(/\s/g, '').split(',');
        return allowedMimeTypes.indexOf(file[0].type) !== -1;
    };


    return {
        Textarea: {
            updateWordCount: updateWordCount
        },
        Validator: {
            Parsley: {
                validateBirthday: validateBirthday
            },

            validateZipCode: validateZipCode,
            validateTaxIdNumber: validateTaxIdNumber,
            validateIBAN: validateIBAN,
            validateBIC: validateBIC,
            validateMaxFileSize: validateMaxFileSize,
            validateFileMimeType: validateFileMimeType,
        }
    };
})($);


// Provide objects for unit testing on the cli.
if (typeof module !== 'undefined') {
    module.exports.FormElement = FormElement;
}
