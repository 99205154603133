/**
 *
 * @date   10.07.2017 13:49
 * @author Michael Raith <michael.raith@bcmsolutions.de>
 */

/* global jQuery, module */

/**
 * Helper class to handle loading spinner.
 *
 * @type {{getLoadingSpinner}}
 */
var LoadingSpinner = (function ($) {
    'use strict';

    /**
     *
     * @type {{loader: string, modal: string}}
     */
    var selector = {
        loader: '.loader-template',
        modal: '.modal-loader',
    };


    /**
     * Get a fresh loading spinner copy.
     * This will return a copied html dom element added into the base html.
     *
     * @return {jQuery|HTMLElement}
     */
    var getLoadingSpinner = function () {
        return $(selector.loader)
            .clone()
            .removeClass('loader-template')
            .addClass('loader-id-' + Math.floor((Math.random() * 100000)))
            .show();
    };


    /**
     * Display a modal loading spinner and add an optional title to it.
     *
     * @param {string} loaderTitle
     */
    var showModalLoader = function (loaderTitle) {
        loaderTitle = (loaderTitle || '');

        var $modal = $(selector.modal);

        $modal.find('.modal-title').text(loaderTitle);

        $modal.modal('show');
    };

    /**
     * Hide the modal loading spinner.
     */
    var hideModalLoader = function () {
        $(selector.modal).modal('hide');
    };


    return {
        getLoadingSpinner: getLoadingSpinner,
        showModalLoader: showModalLoader,
        hideModalLoader: hideModalLoader,
    };
})(jQuery);


// Provide objects for unit testing on the cli.
if (typeof module !== 'undefined') {
    module.exports.LoadingSpinner = LoadingSpinner;
}
