/**
 * Main JavaScript element to startup the application.
 * Don't worry about "console.log" etc., because they will be removed by the build process.
 *
 * @date   10.04.2017 16:30
 * @author Michael Raith <michael.raith@bcmsolutions.de>
 */

/* global $, window, Condition, FormElement, NavigationHelper */

$(function () {
    'use strict';

    var startTime = Date.now();

    /**
     * Reset the element to default values
     *
     * @param $sourceElement
     */
    var resetElement = function resetElement($sourceElement) {
        if ($sourceElement.is(':radio')) {
            $sourceElement.remove('checked');
            $sourceElement.attr('checked', false);
            $sourceElement.prop('checked', false);
        } else if ($sourceElement.is('input[type!=\'hidden\']')) {
            $sourceElement.val('');
        }
        if ($sourceElement.is('select')) {
            $sourceElement.prop('selectedIndex',0);
        }
        if ($sourceElement.is('checkbox')) {
            $sourceElement.prop('checked', false);
        }
    };

    /**
     * Show hide elements based on conditions and optionally reset user input
     *
     * @param $sourceElement
     * @param mainConditionObject
     * @param reset
     */
    var conditionChangeState = function conditionChangeState($sourceElement, mainConditionObject, reset) {
        var state = Condition.isAllEqual(mainConditionObject);
        // Some elements are wrapped in containers. Get their uppermost parent element.
        var $parentSourceElement = $sourceElement;

        // Source element is of type "ProductBlockType".
        if ($sourceElement.hasClass('is-product')) {
            $parentSourceElement = $sourceElement.parents('.form-group.row');
            if (reset) {
                resetElement($sourceElement);
            }
        } else {
            $parentSourceElement.find('input, select, textarea').each(function(k, v) {
                if (reset) {
                    resetElement($(v));
                }
            });
        }

        // Display/hide source element depending on condition state.
        $parentSourceElement[state ? 'show' : 'hide']();
    };

    /**
     * Return a on-change event handler. Provide a closure to store condition data and the source element
     * to which the condition belongs to.
     *
     * @param {jQuery|object} $sourceElement      Condition belongs to this source element.
     * @param {object}        mainConditionObject Main condition data object to execute to check all condition sub-values
     *                                            for the main condition.
     *
     * @returns {function|onChangeHandler}
     */
    var prepareOnChangeHandler = function prepareOnChangeHandler($sourceElement, mainConditionObject) {
        return function onChangeHandler() {
            return conditionChangeState($sourceElement, mainConditionObject, true);
        }
    };

    /**
     * Init all form conditions defined in the configuration file and which are prepared in the backend.
     * Iterate over all elements containing conditions and prepare logic handle them in the frontend.
     *
     * @returns {Promise.<T>}
     */
    var initConditions = function () {
        var $els = $('.has-condition');
        // console.log('has condition elements', $els);

        $els.each(function (key, element) {
            var $sourceElement = $(element);
            /** @type {{conditions: {object[]}, type: {string}}} */
            var mainConditionObject = $sourceElement.data('condition');
            // console.log('source element', $sourceElement);
            // console.log('main condition object', mainConditionObject);

            if (mainConditionObject && mainConditionObject.conditions) {
                var targetElements = [];

                // Register a handler for every condition as every target element might trigger a condition.
                mainConditionObject.conditions.forEach(function (conditionData) {
                    // console.group('target element');
                    // var preparedId = '.id_' + conditionData.targetId;
                    // var $elTarget = $(preparedId);
                    // console.log('id: "%s"\tprepared id: "%s"', conditionData.targetId, preparedId);
                    // console.log('element', $elTarget);
                    // console.log('value: "%s"', $elTarget.find('input, select').val());
                    // console.log('on change selector: "%s"', Condition.prepareSelector(conditionData.targetId));
                    // console.groupEnd();

                    var preparedSelector = Condition.prepareSelector(conditionData.targetId);

                    $('body').on(
                        'change',
                        preparedSelector,
                        // Some conditions are only valid in combination, so we always pass down the original condition main object.
                        prepareOnChangeHandler($sourceElement, mainConditionObject)
                    );

                    targetElements.push(preparedSelector);
                });

                // #1: Iterate over all condition elements.
                targetElements.forEach(function (selector) {
                    // #2: Select all target elements ...
                    var $targetElement = $(selector);
                    // #3: ... get their current value ...
                    var selectedValue = $targetElement.val();
                    // #4: ... and check if the current value is not the first value (default value).
                    var optionOrInputElementsIndex = $targetElement.find('option, input').filter('[value="' + selectedValue + '"]').index();

                    if (optionOrInputElementsIndex > 0) {
                        // #5: If a non default value is selected, trigger a "change" event to execute condition handlers.
                        $targetElement.trigger('change');
                    }
                });

                // 6. trigger condition check without reset - this covers page reload
                conditionChangeState($sourceElement, mainConditionObject, false);            }
        });

        return Promise.resolve();
    };

    /**
     * Init and prepare character counter on form textarea elements.
     *
     * @returns {Promise.<T>}
     */
    var initCharacterCounter = function () {
        var textAreaIds = [];

        $('.character-counter').each(function (key, el) {
            textAreaIds.push('#' + el.id);
        });

        $('body').on('keyup', textAreaIds, FormElement.Textarea.updateWordCount);

        return Promise.resolve();
    };

    /**
     * Init frontend form validation to give the user some feedback on its input.
     *
     * @returns {Promise.<T>}
     */
    var initFormValidation = function () {
        var $navTabs = $('.nav-tabs');
        var $steps = $('.book-form .step');

        // #1: Iterate over all steps ...
        $steps.each(function (stepNumber, element) {
            // #2: ... and prepare all validation groups. One group for every step.
            $(element).find(NavigationHelper.elementsToCheck.join(',')).each(function (i, inputElement) {
                $(inputElement).attr('data-parsley-group', 'step-' + stepNumber);
            });
        });

        // #3: validation will be triggered if the user tries to access the next step.
        $('body')
            // Previous button is easy, just go back
            .on('click', '.form-navigation .previous', function (e) {
                NavigationHelper.previousStep(e, $steps, $navTabs);
            })
            // Next button goes forward iff current block validates
            .on('click', '.form-navigation .next', function (e) {
                NavigationHelper.nextStep(e, $steps, $navTabs);
            })
            // Disable enter submit form
            .on('keyup keypress', function (e) {
                var keyCode = e.keyCode || e.which;
                if (keyCode === 13) {
                    e.preventDefault();
                    return false;
                }
            });

        return Promise.resolve();
    };

    /**
     * Init custom Parsley.JS validation methods.
     *
     * @returns {Promise.<T>}
     */
    var initParsleyValidator = function () {
        void 0;
        window.Parsley.addValidator('zipcode', {
            /**
             * Validate if a zip-code matches for a given country.
             *
             * @param {string|number} value       Zip code value to validate.
             * @param {*}             requirement
             * @param {object}        instance    Parsley form element instance.
             *
             * @returns {boolean}
             */
            validateString: function (value, requirement, instance) {
                var country = '';

                // Search for the next country element which contains "ship-country" in its id.
                $(instance.$element).parents('.form-group').nextAll('.form-group').each(function (key, el) {
                    var $shippingCountry = $(el).find('[id*="ship-country"]');
                    // console.log('$shippingCountry', $shippingCountry, $shippingCountry.val());

                    if ($shippingCountry.length) {
                        country = $shippingCountry.val();
                    }
                });
                // console.log('selected country: "%s"', country);

                return FormElement.Validator.validateZipCode(value, country);
            },
            messages: {
                en: 'There is no such zip for the selected country',
                de: 'Die PLZ ist für das ausgewählte Land nicht gültig'
            }
        });

        window.Parsley.addValidator('taxid', {
            /**
             * Validate if a tax id number matches for a given country.
             *
             * @param {string|number} value       Tax id number value to validate.
             * @param {*}             requirement
             * @param {object}        instance    Parsley form element instance.
             *
             * @returns {boolean}
             */
            validateString: function (value, requirement, instance) {
                var country = '';

                // Search for the next country element which contains "ship-country" in its id.
                $(instance.$element).parents('.form-group').prevAll('.form-group').each(function (key, el) {
                    var $shippingCountry = $(el).find('[id*="ship-country"]');
                    // console.log('$shippingCountry', $shippingCountry, $shippingCountry.val());

                    if ($shippingCountry.length) {
                        country = $shippingCountry.val();
                    }
                });
                // console.log('selected country: "%s"', country);

                return FormElement.Validator.validateTaxIdNumber(value, country);
            },
            messages: {
                en: 'There is no such tax id number for the selected country',
                de: 'Die Ust-ID ist für das ausgewählte Land nicht gültig'
            }
        });

        window.Parsley.addValidator('iban', {
            /**
             * Validate if a IBAN matches for a given country.
             *
             * @param {string|number} value       Zip code value to validate.
             * @param {*}             requirement
             * @param {object}        instance    Parsley form element instance.
             *
             * @returns {boolean}
             */
            validateString: function (value/*, requirement, instance*/) {
                var country = '';

                var matches = value.match(/^([A-Z]{2})/i);
                if (matches && matches.length > 0) {
                    country = matches[1];
                }
                // console.log('selected country: "%s"', country);

                return FormElement.Validator.validateIBAN(value, country);
            },
            messages: {
                en: 'IBAN is invalid',
                de: 'Die eingegebene IBAN ist nicht gültig'
            }
        });

        window.Parsley.addValidator('bic', {
            /**
             * Validate if a BIC matches for a given country.
             *
             * @param {string|number} value       Zip code value to validate.
             * @param {*}             requirement
             * @param {object}        instance    Parsley form element instance.
             *
             * @returns {boolean}
             */
            validateString: function (value/*, requirement, instance*/) {
                return FormElement.Validator.validateBIC(value);
            },
            messages: {
                en: 'BIC/SWIFT is invalid',
                de: 'Die eingegebene BIC/SWIFT ist nicht gültig'
            }
        });

        window.Parsley.addValidator('birthday', {
            requirementType: 'integer',
            validateNumber: FormElement.Validator.Parsley.validateBirthday,
            messages: {
                en: 'Entered date value %s is not valid',
                de: 'Das eingegebene Datumswert %s ist nicht gültig'
            }
        });

        window.Parsley.addValidator('maxfilesize', {
            requirementType: 'integer',
            validateString: function(value, requirement, instance) {
                return FormElement.Validator.validateMaxFileSize(value, requirement, instance);
            },
            messages: {
                en: 'The file is bigger than 10Mb.',
                de: 'Die Datei ist größer als 10Mb.'
            }
        });

        window.Parsley.addValidator('filemimetypes', {
            requirementType: 'string',
            validateString: function (value, requirement, instance) {
                return FormElement.Validator.validateFileMimeType(value, requirement, instance);
            },
            messages: {
                en: 'File mime type not allowed',
                de: 'Nur Bilder oder PDF Dateien erlaubt.'
            }
        });

        void 0;

        return Promise.resolve();
    };

    /**
     * Add a handler on html5 number input fields to limit their length. Input number fields are only limited by the
     * spinner controls, but not on manual field input.
     *
     * @returns {Promise.<T>}
     */
    var initInputFields = function () {
        $('body').on('input', 'input[type="number"]', function (e) {
            var $el = $(e.target);
            var maxLength = String($el.prop('max')).length;
            var value = $el.val();

            if (maxLength && value > maxLength) {
                $el.val(String(value).slice(0, maxLength));
            }
        });

        return Promise.resolve();
    };

    /**
     * Credit card type select handler.
     *
     * @param {Event|jQuery.Event} e
     */
    var fncPaymentChangeHandler = function (e) {
        var $el = $(e.target);
        var val = $el.val();

        // Fetch all payment blocks.
        var paymentElements = $el.parents('.form-group').nextAll('.payment-type');

        // Hide all payment elements.
        paymentElements.hide();

        paymentElements.find('input').each(function (key, element) {
            var $el = $(element);

            // Disable required attribute to disable frontend validation.
            $el.prop('required', false);

            // Unset this checkbox every time the user changes the payment type.
            if ($el.attr('type') === 'checkbox' && $el.data('unset-checkbox')) {
                $el.prop('checked', false);
            }
        });

        // No payment type selected. Just hide everything and continue.
        if (!val || val.length < 1) {
            return;
        }

        var selectedPaymentElement = paymentElements.filter(function (key, element) {
            return $(element).hasClass('payment-type-' + val);
        });

        selectedPaymentElement.find('input').each(function (key, element) {
            var $el = $(element);

            // Set required state on all previously marked fields to enable frontend validation on them.
            $el.prop('required', $el.data('required'));
        });

        // Pre fill some fields for "direct debit".
        if ('Y' === val) {
            var $bookForm = $('.book-form');
            var getElementContent = function (name) {
                return $bookForm.find('input[name*="' + name + '"]').eq(0).val();
            };

            selectedPaymentElement.find('[name*="account-owner"]').val(
                getElementContent('firstname') + ' ' + getElementContent('lastname')
            );
        }

        // Display payment option.
        selectedPaymentElement.show();
    };

    /**
     * Init payment select element.
     *
     * @returns {Promise.<T>}
     */
    var initPayment = function () {
        var paymentSelector = '#form_payment-type_type';
        $('body').on('change', paymentSelector, fncPaymentChangeHandler);

        return new Promise(function (resolve) {
            // Trigger a change event on the payment selector to properly init it if a payment option is already set by the
            // backend.
            setTimeout(function () {
                $(paymentSelector).trigger('change');

                resolve();
            }, 16);
        });
    };

    Promise
        .all([
            initConditions(),
            initCharacterCounter(),
            initFormValidation(),
            initParsleyValidator(),
            initInputFields(),
            initPayment(),
        ])
        .then(function () {
            void 0;
        })
        .catch(function (err) {
            void 0;
        });
});
