/**
 *
 * @date   20.06.2017 13:51
 * @author Michael Raith <michael.raith@bcmsolutions.de>
 */

/* global $, module, window, RequestHelper */

/**
 * Helper methods for step navigiation and state machine handling.
 *
 * @type {{elementsToCheck, previousStep, nextStep, getNavPrevButton, getNavNextButton}}
 */
var NavigationHelper = (function ($, window) {
    'use strict';

    var parsleyOptions = {
        /**
         * Adjust the error container if an input element has a previous select box or another input element.
         * We need this adjustment e.g. for the input group "telephon number" which consists of a dropdown for
         * the country code and the actual phone number.
         *
         * @param {jQuery} el
         *
         * @returns {jQuery}
         */
        errorsContainer: function (el) {
            var type = el.$element.prop('type');

            // Input elements with a previous element/dropdown.
            if (el.$element.prev('input, select').length > 0) {
                return el.$element.parents('.input-group').parent();
            }
            // Radio buttons
            else if (type === 'radio') {
                return el.$element.parents('.form-check-label').parent();
            }
            // Checkbox
            else if (type === 'checkbox') {
                return el.$element.parent();
            }

            return el;
        },

        // Exclude all hidden elements to successfully perform a form validation with hidden form parts.
        excluded: 'input[type=button], input[type=submit], input[type=reset], input[type=hidden], [disabled], :hidden'
    };

    var elementsToCheck = [
        'input.form-control',
        'input.form-control-file',
        'textarea.form-control',
        'select.form-control[required]',
        // Radio buttons
        'input.form-check-input[required]',
        // Checkbox elements
        'input[type="checkbox"][required]',
    ];

    var selectors = {
        form: '.book-form',
        nav: '.form-navigation',
        navNext: '.next',
        navPrev: '.previous',
        //navSubmit: '[type=submit]',
        payment: '#form_payment-type',
    };


    /**************************************************
     * Helper methods
     */

    /**
     * Get active step element.
     *
     * @param {jQuery[]|HTMLElement[]} $steps
     *
     * @return {jQuery|HTMLElement}
     */
    var getActiveStep = function ($steps) {
        return $steps.filter('.active');
    };

    /**
     * Check if current step contains an element by its selector.
     *
     * @param {jQuery[]|HTMLElement[]} $steps
     * @param {string}                 selector
     *
     * @returns {boolean}
     */
    var isStep = function ($steps, selector) {
        return (getActiveStep($steps).find(selector).length >= 1);
    };

    /**
     * Check if the current step is the "payment information" step.
     *
     * @param {jQuery[]|HTMLElement[]} $steps
     *
     * @return {boolean}
     */
    var isPaymentStep = function ($steps) {
        return isStep($steps, selectors.payment);
    };

    /**
     * Check if the current step is the "verify input data" step.
     *
     * @param {jQuery[]|HTMLElement[]} $steps
     *
     * @return {boolean}
     */
    var isVerifyInputDataStep = function ($steps) {
        return isStep($steps, '#block_verify_input_data');
    };

    /**
     * Get the main navigation wrapper element.
     *
     * @returns {jQuery|HTMLElement}
     */
    var getNavigation = function () {
        return $(selectors.nav);
    };

    /**
     * Get the navigation's "previous" button.
     *
     * @returns {jQuery|HTMLElement}
     */
    var getNavPrevButton = function () {
        return getNavigation().find(selectors.navPrev);
    };

    /**
     * Get the navigation's "next" button.
     *
     * @returns {jQuery|HTMLElement}
     */
    var getNavNextButton = function () {
        return getNavigation().find(selectors.navNext);
    };

    /**
     * Check if an element group has form elements with (validation) errors.
     *
     * @param {jQuery} $el
     *
     * @return {boolean}
     */
    var hasFormErrors = function ($el) {
        // console.log('$response has errors?', $el.find('.is-invalid, .parsley-error'));

        return ($el.find('.is-invalid, .parsley-error').length > 0);
    };

    /**
     * Trigger a backend request to get all prepared data for the "verify input data" page.
     */
    var verifyInputData = function verifyInputData() {
        var $form = $('.book-form');
        var url = $form.data('verify-input-data-url');
        // console.log('form', $form);
        // console.log('serialized', $form.serializeArray());

        var $targetOutputElement = $form.find('#block_verify_input_data');
        // Empty target element first and add loading spinner to it.
        $targetOutputElement
            .empty()
            .append(window.LoadingSpinner.getLoadingSpinner());

        // Re-enable verify checkbox on that step.
        $targetOutputElement
            .parent()
            .find(':checkbox:not([readonly]):not(:disabled)')
            .prop('checked', false);

        RequestHelper.postForm(url, $form.get(0))
            .then(function (response) {
                // console.log('got fetch api response', response);

                if (!response || !response.data) {
                    return;
                }

                // Parse result data to check if we got some valid html content.
                var $verifyInputFormData = $(response.data);
                // console.log('$verifyInputFormData', $verifyInputFormData);

                if ($verifyInputFormData && $verifyInputFormData.length > 0) {
                    // console.info('valid result data!');

                    /*
                     * There seems to be an error somewhere in the symfony form engine, which does not bubble down
                     * the attribute "disabled" properly". Add attribute "disabled" to every radio-button and
                     * checkbox manually.
                     */
                    $verifyInputFormData
                        .find('input, select, textarea')
                        .prop('disabled', true)
                        .prop('readonly', true);

                    // Remove character counter.
                    $verifyInputFormData
                        .find('.character-counter')
                        .removeClass('character-counter')
                        .next('.counter')
                        .remove();

                    // Remove some example text on how to fill a form field.
                    $verifyInputFormData
                        .find('.form-select-example')
                        .remove();

                    // Remove non checked radio buttons.
                    $verifyInputFormData
                        .find(':radio:not(:checked)')
                        .parent()
                        .hide();

                    // Remove empty blocks.
                    $verifyInputFormData
                        .find('.card')
                        .filter(function (k, el) {
                            return $(el).text().trim() === '';
                        })
                        .remove();

                    // Remove all id-attributes to avoid collisions with the main form.
                    $verifyInputFormData
                        .find('[id]')
                        .removeAttr('id');

                    // Remove parsley validation information.
                    $verifyInputFormData
                        .find('.parsley-success, .parsley-error')
                        .removeClass('parsley-success parsley-error');

                    // Prepare credit card payment information block.
                    $verifyInputFormData
                        .find('.multi-cc-payment-collapse')
                        .filter('.multi-cc-payment-form').removeClass('show').end()
                        .filter('.multi-cc-payment-display').addClass('show').end()
                        .find('.btn').remove();

                    // Output form content.
                    $targetOutputElement
                        .empty()
                        .append($verifyInputFormData);
                }
            });
    };

    /**
     * Validate form on step change.
     *
     * @param {jQuery[]|HTMLElement[]} $steps
     *
     * @returns {Promise.<T>}
     */
    var validateForm = function validateForm($steps) {
        var $form = $(selectors.form);
        var url = $form.data('validate-form-url');

        var errorHandler = function (response) {
            // console.log('got fetch api response', response);

            if (response) {
                if (response.errors && response.errors.length > 0) {
                    // console.warn('Got validation errors by the backend', response.errors);

                    if (response.data) {
                        var $activeStep = getActiveStep($steps);
                        var activeStepId = ('#' + $activeStep.prop('id'));
                        // console.log('active step', $activeStep, $activeStep.prop('id'));
                        var $response = $(response.data);

                        // Check if the current step in the response has errors.
                        if (hasFormErrors($response)) {
                            // Replace current step with the response's step containing error messages.

                            $(activeStepId)
                                .html(
                                    $response.find(activeStepId).html()
                                )
                                // Jump to first error message.
                                .find('.is-invalid').focus();
                        } else {
                            void 0;
                        }
                    } else {
                        void 0;
                        // TODO: display the user some kind of nice error message.
                    }

                    return Promise.reject(response.errors);
                }
            } else {
                void 0;
            }
        };

        return window.RequestHelper.postForm(url, $form.get(0))
            .then(errorHandler)
            .catch(errorHandler);
    };


    /**************************************************
     * Methods for step navigation and verification.
     */

    /**
     * Navigate to step.
     *
     * @param {jQuery[]|HTMLElement[]} $steps
     * @param {jQuery[]|HTMLElement[]} $navTabs
     * @param {number}                 index
     *
     * @return {Promise}
     */
    var navigateTo = function ($steps, $navTabs, index) {
        var promise;

        $steps.removeClass('active');
        var $activeElement = $steps.eq(index);
        $activeElement.addClass('active');

        // Reset all wizard steps first.
        var $wizardSteps = $navTabs.find('.bs-wizard-step');

        $wizardSteps.removeClass('active disabled complete');
        // Mark current step as active.
        $wizardSteps.eq(index).addClass('active');
        // Mark all previous steps as "complete" ...
        $wizardSteps.filter(':lt(' + index + ')').addClass('complete');
        // ... and disable all next steps.
        $wizardSteps.filter(':gt(' + index + ')').addClass('disabled');

        // Show only the navigation buttons that make sense for the current section:
        getNavPrevButton().toggle(index > 0);
        var atTheEnd = (index >= $steps.length - 1);
        getNavNextButton().toggle(!atTheEnd);
        // $(selectors.nav).find(selectors.navSubmit).toggle(atTheEnd);

        // Execute method if step contains "verify input data" block element.
        if (isVerifyInputDataStep($steps)) {
            promise = verifyInputData();
        } else {
            promise = Promise.resolve();
        }

        // Scroll to tab content's top.
        $activeElement.get(0).scrollIntoView();

        return promise;
    };

    /**
     * Get the current active step's index.
     * The index can be used to trigger form validation in that step.
     *
     * @param {jQuery[]|HTMLElement[]} $steps
     *
     * @returns {number}
     */
    var currentStepIndex = function ($steps) {
        return $steps.index(getActiveStep($steps));
    };

    /**
     * Navigate user to previous step.
     *
     * @param {Event}                  e
     * @param {jQuery[]|HTMLElement[]} $steps
     * @param {jQuery[]|HTMLElement[]} $navTabs
     *
     * @returns {boolean}
     */
    var previousStepHandler = function (e, $steps, $navTabs) {
        e.preventDefault();
        e.stopPropagation();

        var index = currentStepIndex($steps);
        if ((index - 1) >= 0) {
            navigateTo($steps, $navTabs, index - 1);
        }

        return false;
    };

    /**
     * Navigate user to next step.
     *
     * @param {Event}                  e
     * @param {jQuery[]|HTMLElement[]} $steps
     * @param {jQuery[]|HTMLElement[]} $navTabs
     *
     * @returns {boolean}
     */
    var nextStepHandler = function (e, $steps, $navTabs) {
        e.preventDefault();
        e.stopPropagation();

        var index = currentStepIndex($steps);
        if ((index + 1) < $steps.length) {
            $(selectors.form)
                // Trigger parsley form validation.
                .parsley(parsleyOptions)
                .whenValidate({group: 'step-' + index, force: true})

                // Validate form data.
                .then(function () {
                    window.LoadingSpinner.showModalLoader();
                    // Disable button ...
                    getNavNextButton().prop('disabled', true);

                    var promiseObject;

                    if (isPaymentStep($steps)) {
                        // Validate user payment data by the backend.
                        promiseObject = window.PaymentHelper.validatePayment()
                    } else {
                        promiseObject = validateForm($steps);
                    }

                    return promiseObject
                        .then(function () {
                            // ... until validation has finished.
                            getNavNextButton().prop('disabled', false);
                        })
                        .catch(function (err) {
                            // Enable button also after an error, so the user can re-trigger a new validation.
                            getNavNextButton().prop('disabled', false);

                            // Re-throw the error so further steps are skipped.
                            throw err;
                        });
                })

                // Finally refresh the current payment information (product payment sum etc.) and navigate to the next step.
                .then(function () {
                    window.LoadingSpinner.hideModalLoader();
                    return Promise.all([
                        window.PaymentHelper.refreshPaymentInfo(),
                        navigateTo($steps, $navTabs, index + 1),
                    ]);
                });
        }

        return false;
    };

    return {
        elementsToCheck: elementsToCheck,
        previousStep: previousStepHandler,
        nextStep: nextStepHandler,
        getNavPrevButton: getNavPrevButton,
        getNavNextButton: getNavNextButton,
    };
})($, window);


// Provide objects for unit testing on the cli.
if (typeof module !== 'undefined') {
    module.exports.NavigationHelper = NavigationHelper;
}
