/**
 *
 * @date   21.08.2017 15:00
 * @author Michael Raith <michael.raith@bcmsolutions.de>
 */

/* global jQuery, module */

var Translation = (function ($) {
    'use strict';

    /**
     *
     * @type {{translations: string}}
     */
    var selector = {
        translations: '.translations',
    };


    /**
     *
     * @param {string} key
     *
     * @return {string}
     */
    var getTranslation = function (key) {
        return $(selector.translations).data(key);
    };

    return {
        getTranslation: getTranslation,
    };
})(jQuery);


// Provide objects for unit testing on the cli.
if (typeof module !== 'undefined') {
    module.exports.Translation = Translation;
}

